import React, {useEffect, useState} from "react";
import { GoogleApiWrapper } from 'google-maps-react'
import Style from './data/findDealerMapStyles';
import view from "./findDealer.module.scss";
const { siteMetadata } = require('../../../gatsby-config');
const googleAPIkey = siteMetadata.googleAPIkey;

const FindDealerMap = (props) => {

  //Define states and context
  const [mapInteraction, setMapInteraction] = useState('');
  const mapProps = props.props;
  const google = props.google;
  const style = Style;
  const lat = mapProps.dealerLocals[0].dealerLat;
  const long = mapProps.dealerLocals[0].dealerLong;
  const markers = mapProps.dealerLocals;
  const mapMarkerLink = view["map-marker"];

  useEffect(()=>{
    initMap();
  })


  useEffect(()=>{
    let mobileScreenSize = (window.innerWidth <= 760);
    if(mobileScreenSize){
      setMapInteraction('click')
    }else{
      setMapInteraction('mouseover')
    }
  })

 const initMap = () => {

    const node = document.getElementById("map");
    const mapConfig = Object.assign(
      {},
      {
        //zoom: (window.innerWidth <=760) ? 5 : 6,
          maxZoom: 18,
        center: { lat: parseInt(lat), lng: parseInt(long) }, 
        containerStyle:{ height:'100%' },
        style:{height:'100%', width:'100%'},
        mapTypeControl:false,
        streetViewControl:false,
        zoomControl:false,
        fullScreenControl:false,
        google:google,
        styles:style  
      }
    );

    const map = new google.maps.Map(node, mapConfig);

    let infowindow = new google.maps.InfoWindow();
    let bounds = new google.maps.LatLngBounds();
    let marker, i;

    for (i = 0; i < markers.length; i++) {  
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(markers[i].dealerLat, markers[i].dealerLong),
        map: map
      });
      bounds.extend(marker.position);
      google.maps.event.addListener(marker, mapInteraction, (function(marker, i) {
        const contentString =  '<div id="content" style="padding: 10px;">' +
        `<h1 id="firstHeading" class="firstHeading" style="margin:5px 0 0 0">${markers[i].dealerName}</h1>` +
        `<p style="margin:2px auto 10px auto; font-size:15px; text-align:center;"><b>${markers[i].dealerAddress}</b></p> ` +
        `<a class="${mapMarkerLink}" href=${markers[i].websiteLink} target="_blank">` +
        `Visit Dealer Website</a>` +
        "</div>";
        return function() {
          infowindow.setContent(contentString);
          infowindow.open(map, marker);
        }
      })(marker, i));
    }
    map.fitBounds(bounds)
  }

  return (
    <div id="map" className={view["map"]}></div>
  )
}

export default GoogleApiWrapper({
  apiKey: (googleAPIkey) // Google Maps API Key
})(FindDealerMap);
