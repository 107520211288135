import React, { useState, useEffect, useContext } from "react";
import { FindDealerContext }  from "./context/findDealerContext";
import view from "./findDealer.module.scss";
import MapMarkerIcon from './assets/mapMarkerIcon';

const { siteMetadata } = require('../../../gatsby-config');
const googleAPIkey = siteMetadata.googleAPIkey;

const FindDealerMobileHome = (props) =>{
   const [formZip, setFormZip] = useState('');

  //Define context
  const findDealerProps = useContext(FindDealerContext);

  /**
   * Define values from findDealerContext
   * 
   * @param {method} searchZipCode calls three other functions from context setZipcode, isThereHistory, distanceRef
   * and passes the zipcode searched.
   */

  const {searchZipCode, scrollToListElement} = findDealerProps;

  const keyPress = (e, formZip) => {
    if (e.keyCode === 13) {
      searchZipCode(formZip);
    }
  }


  function holdFormZip(val){
    setFormZip(val);
  }

  function submission(e, formZip){
      searchZipCode(formZip);
      e.preventDefault();
      scrollToListElement();
  }

  return(
    <div className={view["mobile-search"]}>
      <p className={view["find-a-dealer-para"]}>Find Your Nearest <span></span> Dealer</p>
      <form id="find-a-dealer" className={view["search-box-mobile"]} onSubmit={(e)=>submission(e, formZip)} autoComplete="off">
      <label hidden for="find-a-dealer">Find a Dealer</label>
        <div className={view["search-input-mobile"]}>
          <MapMarkerIcon/>
          <input maxLength="5" maxLength="5" minLength="5" className={view["zipcode-input"]}  required name="searchedZip" label="zip code" type="text" placeholder="Enter Zipcode" wrap="hard" onChange={(e)=>holdFormZip(e.target.value)} onKeyDown={(e)=>keyPress(e, formZip)}/>
        
        
        </div>
        <button className={view["search-button-mobile"]} onClick={(e)=>submission(e, formZip)}>
          SEARCH
        </button>
      </form>

    </div>
  );

}

export default FindDealerMobileHome;